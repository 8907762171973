import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "./../images/logo.png"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Home" />
      <div className="error-page">
        <div class="content">
          <div class="pulse-logo">
            <img src={logo} alt="Logo" />
          </div>
          <h2>Oops this page is under construction.</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
  </Layout>
)

export default NotFoundPage
